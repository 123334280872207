import { currentStoreView } from './currentStoreView'

export function adjustMultistoreApiUrl (url: string): string {
  const storeView = currentStoreView();

  if (storeView.storeCode) {
    const urlSep = (url.indexOf('?') > 0) ? '&' : '?';

    if (url.indexOf('storeCode') < 0) {
      url += urlSep + 'storeCode=' + storeView.storeCode;
    }
  }

  return url
}
