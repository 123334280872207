import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'

Vue.use(VueI18n)

export class I18n {
  private static instance: I18n;

  private static defaultLang = config.i18n.defaultLocale || 'en-US';

  public static getInstance() {
    if (!I18n.instance) {
      I18n.instance = new I18n(I18n.defaultLang);
    }

    return I18n.instance;
  }

  private _i18n: VueI18n;

  private loadedLanguages = new Set<string>();

  private constructor(lang: string) {
    // Instantiate a new VueI18n instance
    this._i18n = new VueI18n({
      locale: config.i18n.bundleAllStoreviewLanguages ? config.i18n.defaultLocale : I18n.defaultLang, // set locale
      fallbackLocale: I18n.defaultLang,
      messages: {}
    });

    // Initialize the newly created VueI18n instance with translation messages
    this.loadLanguageAsync(lang, true);
  }

  public hasLoaded(lang: string) {
    return this.loadedLanguages.has(lang);
  }

  public get i18n() {
    return this._i18n;
  }

  /**
   * Set the currently active language
   */
  public setI18nLanguage(lang) {
    this.i18n.locale = lang;

    return lang;
  }

  /**
   * Load the specified locale async
   */
  public async loadLanguageAsync (lang: string, force = false): Promise<string> {
    if (this.loadedLanguages.has(lang)) {
      return '';
    }

    if (!config.i18n.bundleAllStoreviewLanguages) {
      if (force || this.i18n.locale !== lang) {
        try {
          this.loadedLanguages.add(lang) // Mark language as being loaded up front to dissallow concurrent loadings
          await this.loadDateLocales(lang)

          const msgs = await import(`theme/resource/i18n/${lang}.csv`)
          this.i18n.setLocaleMessage(lang, msgs.default)
          return this.setI18nLanguage(lang)
        } catch (e) { // eslint-disable-line handle-callback-err
          Logger.debug('Unable to load translation')()
          this.loadedLanguages.delete(lang);

          return ''
        }
      }
    } else {
      this.loadedLanguages.add(lang) // Mark language as being loaded upfront to dissallow concurrent loadings

      await this.loadDateLocales(lang)

      return this.setI18nLanguage(lang)
    }

    return lang
  }

  /**
   * Lazy load date locales file for current switched language.
   */
  public async loadDateLocales(lang: string = 'en') {
    let localeCode = lang.toLocaleLowerCase();
    const separatorIndex = localeCode.indexOf('-');
    localeCode = separatorIndex ? localeCode.substr(0, separatorIndex) : localeCode;

    await import(`dayjs/locale/${localeCode}.js`);
  }
}

export default I18n.getInstance().i18n;
