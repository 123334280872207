
import Vue from 'vue'

/** Clear sync tasks that were not transmitted yet */
export function clearNotTransmited () {
  const syncTaskCollection = Vue.prototype.$db.syncTaskCollection
  syncTaskCollection.iterate((task, id, iterationNumber) => {
    if (!task.transmited) {
      syncTaskCollection.removeItem(id)
    }
  })
}
