/*
  * serial executes Promises sequentially.
  * @param {funcs} An array of funcs that return promises.
  * @example
  * const urls = ['/url1', '/url2', '/url3']
  * serial(urls.map(url => () => $.ajax(url)))
  *     .then(Logger.log.bind(Logger))()
  */
 export const serial = async promises => {
  const results = []
  for (const item of promises) {
    const result = await item;
    results.push(result)
  }
  return results
}
